/*
Dark theme for IntroJs
By: Khashayar Pourdeilami (http://kpourdeilami.github.io)
for: IntroJs (https://github.com/usablica/intro.js)
*/
body {
	.introjs-tooltipReferenceLayer * {
		.regularFont(@enRegularFontPixl);
	}


	.introjs-overlay {
		position: absolute;
		z-index: 999999;
		background: #fff;
		-webkit-transition: all 0.3s ease-out;
		-moz-transition: all 0.3s ease-out;
		-ms-transition: all 0.3s ease-out;
		-o-transition: all 0.3s ease-out;
		transition: all 0.3s ease-out;
	}

	.introjs-fixParent {
		z-index: auto !important;
		opacity: 1.0 !important;
	}

	.introjs-showElement,
	tr.introjs-showElement>td,
	tr.introjs-showElement>th {
		z-index: 9999999 !important;
	}

	.introjs-relativePosition,
	tr.introjs-showElement>td,
	tr.introjs-showElement>th {
		position: relative;
	}

	.introjs-helperLayer {
		position: absolute;
		z-index: 9999998;
		-webkit-transition: all 0.3s ease-out;
		-moz-transition: all 0.3s ease-out;
		-ms-transition: all 0.3s ease-out;
		-o-transition: all 0.3s ease-out;
		transition: all 0.3s ease-out;
	}

	.introjs-helperNumberLayer {
		position: absolute;

		z-index: 9999999999 !important;
		padding: 10px;
		.regularFont(@enRegularFontPixl);
		font-size: 13px;
		top: -40px;
		font-weight: bold;
		color: white;
		text-align: center;
		background: @cyprus;
		width: 20px;
		height: 20px;
		line-height: 20px;
	}

	.introjs-arrow {
		border: 5px solid @cyprus;
		content: '';
		position: absolute;
	}

	.introjs-arrow.top {
		top: -10px;
		border-top-color: transparent;
		border-right-color: transparent;
		border-bottom-color: @cyprus;
		border-left-color: transparent;
	}

	.introjs-arrow.top-right {
		top: -10px;
		right: 10px;
		border-top-color: transparent;
		border-right-color: transparent;
		border-bottom-color: @cyprus;
		border-left-color: transparent;
	}

	.introjs-arrow.top-middle {
		top: -10px;
		left: 50%;
		margin-left: -5px;
		border-top-color: transparent;
		border-right-color: transparent;
		border-bottom-color: @cyprus;
		border-left-color: transparent;
	}

	.introjs-arrow.right {
		right: -10px;
		top: 10px;
		border-top-color: transparent;
		border-right-color: transparent;
		border-bottom-color: transparent;
		border-left-color: @cyprus;
	}

	.introjs-arrow.bottom {
		bottom: -10px;
		border-top-color: @cyprus;
		border-right-color: transparent;
		border-bottom-color: transparent;
		border-left-color: transparent;
	}

	.introjs-arrow.left {
		left: -10px;
		top: 10px;
		border-top-color: transparent;
		border-right-color: @cyprus;
		border-bottom-color: transparent;
		border-left-color: transparent;
	}

	.introjs-tooltip {
		position: absolute;
		padding: 10px;
		background-color: @cyprus;
		min-width: 300px;
		padding-top: 30px;

		max-width: 300px;
		color: @offWhite;
		-webkit-transition: opacity 0.1s ease-out;
		-moz-transition: opacity 0.1s ease-out;
		-ms-transition: opacity 0.1s ease-out;
		-o-transition: opacity 0.1s ease-out;
		transition: opacity 0.1s ease-out;

		text-align: center;
	}

	.introjs-tooltip-title {
		font-weight: 500 !important;
		font-size: 24px;
		color: @green;
	}

	.introjs-tooltiptext {
		font-size: 20px;
	}

	.introjs-tooltipbuttons {
		text-align: right;
		position: relative;
		bottom: -44px;
		margin-left: -10px;
		margin-right: -10px;
		padding: 5px;
		background: @cyprus;
		border-top: none;
	}

	/*
	Buttons style by http://nicolasgallagher.com/lab/css3-github-buttons/
	Changed by Afshin Mehrabani
	Further modified by: Khashayar P.
	*/
	.introjs-button {
		position: relative;
		overflow: visible;
		display: inline-block;
		padding: 0.8em 1.2em;
		background-color: transparent;
		border: none;
		outline: none !important;
		box-shadow: none !important;
		margin: 0;
		text-decoration: none;
		text-shadow: none;
		font-family: @enRegularFontPixl;
		font-size: 20px;
		color: #fff !important;
		white-space: nowrap;
		cursor: pointer;
		outline: none;
		-webkit-background-clip: padding;
		-moz-background-clip: padding;
		-o-background-clip: padding-box;
		/*background-clip: padding-box;*/
		/* commented out due to Opera 11.10 bug */
		/* IE hacks */
		zoom: 1;
		display: inline;
		margin-top: 15px;
	}

	.introjs-button:hover {
		text-decoration: none;
	}

	/* overrides extra padding on button elements in Firefox */
	.introjs-button::-moz-focus-inner {
		padding: 0;
		border: 0;
	}

	.introjs-skipbutton {
		width: 25px;
		height: 25px;
		line-height: 23px;
		margin-right: 5px;
		background-color: @cyprus;
		color: @cyprus;
		border: none;

		&:hover, &:focus, &:active {
			background-color: lighten(@cyprus, 10%);
		}
	}

	.introjs-prevbutton {
		color: @offWhite;
		background-color: transparent;
		border: none;
		border-image: url('~/assets/ui/buttons/basic-button-dark.png') 18 fill / 18px;
		margin-left: 10px;
		margin-bottom: 10px;
		&:hover, &:focus {
			border: none;
			border-image: url('~/assets/ui/buttons/basic-button-dark-hover.png') 18 fill / 18px;
			background-color: transparent !important;
		}
		&:active {
			border: none;
			border-image: url('~/assets/ui/buttons/basic-button-dark-active.png') 18 fill / 18px;
			background-color: transparent !important;
		}
		&.selected {
			border: none;
			border-image: url('~/assets/ui/buttons/basic-button-dark-active.png') 18 fill / 18px;
			background-color: transparent !important;
		}
	}

	.introjs-nextbutton {
		color: @darkBlue !important;
		background-color: transparent;
		border: none;
		border-image: url('~/assets/ui/buttons/bright-green-button.png') 18 fill / 18px;
		margin-right: 10px;
		margin-bottom: 10px;
		&:hover, &:focus {
			border: none;
			border-image: url('~/assets/ui/buttons/bright-green-button-hover.png') 18 fill / 18px;
			background-color: transparent !important;
		}
		&:active {
			border: none;
			border-image: url('~/assets/ui/buttons/bright-green-button-active.png') 18 fill / 18px;
			background-color: transparent !important;
		}
		&.selected {
			border: none;
			border-image: url('~/assets/ui/buttons/basic-button-alt-active.png') 18 fill / 18px;
			background-color: transparent !important;
		}
	}


	.introjs-disabled,
	.introjs-disabled:hover,
	.introjs-disabled:focus {
		color: @offWhite;
		box-shadow: none;
		cursor: default;
		background: transparent;
		border-color: transparent;
	}

	.introjs-bullets {
		text-align: center;
		display: none;
	}

	.introjs-bullets ul {
		clear: both;
		margin: 15px auto 0;
		padding: 0;
		display: inline-block;
	}

	.introjs-bullets ul li {
		list-style: none;
		float: left;
		margin: 0 2px;
	}

	.introjs-bullets ul li a {
		display: block;
		width: 8px;
		height: 8px;
		background: rgba(255, 255, 255, 0.1);
		border-radius: 10px;
		-moz-border-radius: 10px;
		-webkit-border-radius: 10px;
		text-decoration: none;
	}

	.introjs-bullets ul li a:hover {
		background: rgba(255, 255, 255, 0.2);
	}

	.introjs-bullets ul li a.active {
		background: rgba(255, 255, 255, 0.2);
	}

	.introjsFloatingElement {
		position: absolute;
		height: 0;
		width: 0;
		left: 50%;
		top: 50%;
	}
}