@import url('../../web/global/fonts/fonts.less');
@import url('../../web/global/ftue.less');

* {
	box-sizing: border-box;
}

html,
body {
	overflow: hidden;
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: 'Avenir', Helvetica, sans-serif;
	font-size: 16px;
	background-color: black;
}

img {
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	user-drag: none;
}

button {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

#game {
	position: relative;
	width: 100%;
	height: 100%;
}

#main-canvas {
	width: 100%;
	height: 100%;
}

strong {
	font-weight: 600;
	color: #e9e5ad;
}

a {
	font-size: 25px;

	&:link {
		text-decoration: none;
		color: #446ef6;
	}

	/* visited link */
	&:visited {
		color: #cccccc;
	}

	/* mouse over link */
	&:hover {
		color: #fff;
	}

	/* selected link */
	&:active {
		color: #fff;
	}
}

::selection {
	color: @darkGreen;
	background: @offWhite;
}

/* For Mozilla Firefox */
::-moz-selection {
	color: @darkGreen;
	background: @offWhite;
}

::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

::-webkit-scrollbar-thumb {
	background: #fffe8f;
	border-radius: 20px;
}

::-webkit-scrollbar-track {
	background: #0f1853;
	border-radius: 20px;
}

input{caret-color: @darkGreen;}
