@yellowish: #e9e5ad;
@blueishOne: #1dd2ff;
@blueIshTwo: #00fff6;
@blueishThree: #4e8cff;
@white: #ffffff;
@gold: #ffba02;
@red:  #f52b2b;

@offWhite: #FBFDFC;
@cream: #FAFB8E;
@green: #0DD14D;
@pastelGreen: #005a50;
@darkGreen: #006C5B;
@cyprus: #003948;
@darkBlue:#060A23;
@wildStrawberry: #FB3FB2;
@failureRed: #F50F35;
@skyBlue: #86efff;
@discordBlue: #5865F2;

@import (css) url('https://fonts.googleapis.com/css2?family=Do+Hyeon&family=Mouse+Memoirs&family=Noto+Sans+SC:wght@300;400;700&family=Noto+Serif+SC:wght@300;400;600&family=Yanone+Kaffeesatz:wght@300;400;600&display=swap');

@enRegularFontPixl: 'sl_pixlregular';
@enSecondaryFontPT: 'pt_sansregular';
@enSecondaryFontPTBold: 'pt_sansbold';
@enSecondaryFontPTItalic: 'pt_sansitalic';
@enSecondaryFontPTBoldItalic: 'pt_sansbold_italic';

//Simplified Chinese Language Options
@zhHansHeaderFont: 'Noto Sans SC', sans-serif;
@zhHansSecondaryFont: 'Noto Serif SC', serif;
@zhHansRegularFont: 'Noto Serif SC', serif;

.regularFont(@lang) {
	font-family: @lang;
}
